<mat-expansion-panel
  #radioProtecaoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Radioproteção</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    @if (pesquisaProcedimento$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/pesquisaProcedimento']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Pesquisa de procedimentos
      </a>
    }
    @if (cabineIntegrada$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/cabineIntegrada']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Levantamento cabine integrada
      </a>
    }
    @if (cabineExposicao$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/cabineExposicao']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()"
        >Levantamento cabine exposição</a
      >
    }
    @if (levantamentoArea$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/levantamentoArea']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Levantamento de área
      </a>
    }
    @if (radioProtecao7B$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/radioProtecao7B']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Levantamento 7B/Isentos
      </a>
    }
    @if (radioProtecaoARD$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/radioProtecaoARD']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Levantamento ARD
      </a>
    }
    @if (verificacaoProcesso$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/verificacaoProcesso']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Verificação de processos
      </a>
    }
    @if (inserirRelatorioRadioprotecao$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/inserirRelatorioRadioprotecao']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Inserir relatório de radioproteção
      </a>
    }
    @if (consultaRelatorios$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/radioProtecao/consultaRelatorios']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Consulta aos relatórios de radioproteção
      </a>
    }
  </div>
</mat-expansion-panel>
