<mat-accordion class="c-collapse-menu-lateral">
  @if (gestaoAcesso$ | async) {
    <app-gestao-acesso-expansion></app-gestao-acesso-expansion>
  }
  @if (turno$ | async) {
    <app-turno-expansion></app-turno-expansion>
  }
  @if (radioprotecao$ | async) {
    <app-radio-protecao-expansion></app-radio-protecao-expansion>
  }
  @if (ged$ | async) {
    <app-ged-expansion></app-ged-expansion>
  }
  @if (manutencao$ | async) {
    <app-manutencao-expansion></app-manutencao-expansion>
  }
  @if (limpezaConservacao$ | async) {
    <app-limpeza-conservacao-expansion></app-limpeza-conservacao-expansion>
  }
</mat-accordion>
