import { Component, inject } from '@angular/core';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

@Component({
  selector: 'app-collapse-menu-lateral',
  templateUrl: './collapse-menu-lateral.component.html',
  styleUrl: './collapse-menu-lateral.component.scss'
})
export class CollapseMenuLateralComponent {
  private gestaoAcessoService = inject(GestaoAcessoService);
  gestaoAcesso$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomGrupo',
    value: 'gestaoAcesso'
  });
  turno$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomGrupo',
    value: 'turno'
  });
  radioprotecao$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomGrupo',
    value: 'radioprotecao'
  });
  ged$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomGrupo',
    value: 'ged'
  });
  manutencao$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomGrupo',
    value: 'manutencao'
  });
  limpezaConservacao$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomGrupo',
    value: 'limpezaConservacao'
  });
}
